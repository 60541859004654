<template>
  <div v-if="local">
    <h2 class="mb-4">
      Notifications for personal (inc. health) matters
      <span class="optional">(optional)</span>
    </h2>
    <notification-box
      type="info"
      class="mb-4"
      :flat="true"
    >
      <div class="py-1">
        <div class="font-weight-medium mb-2">Information</div>
        <p>
          This section can be used to require your attorney(s) to notify others
          when your attorney(s) begin exercising power for personal (including
          health) matters or whenever they make decisions about specific
          personal matters or health matters.
        </p>
        <p>
          People who can be notified include
          <span
            class="definition-link"
            @click="showDefinition = true"
          >
            you (the principal), the other current attorney(s) that you may have
            appointed and/or a person you nominate.
          </span>
        </p>
      </div>
    </notification-box>

    <h3 class="mb-4">Who would you like to notify?</h3>

    <item-checkbox
      :value="local.personalNotify.me"
      @select="local.personalNotify.me = !local.personalNotify.me"
      title="Me (the principal)"
      class="mb-4"
      ref="meSection"
    />
    <item-checkbox
      v-if="local.attorneys.length > 1 || local.successives.length > 1"
      :value="local.personalNotify.otherAttorneys"
      @select="
        local.personalNotify.otherAttorneys =
          !local.personalNotify.otherAttorneys
      "
      title="My other current attorney(s)"
      class="mb-4"
      ref="otherAttorneysSection"
    />
    <item-checkbox
      :value="showSelectPersons"
      @select="showSelectPersons = !showSelectPersons"
      title="Nominated person(s)"
      ref="otherSection"
      class="mb-4"
    >
      <v-expand-transition>
        <div
          v-if="showSelectPersons"
          class="mx-4"
        >
          <transition
            name="component-fade"
            mode="out-in"
          >
            <div
              v-if="!localFormatted.personalNotify.other.length"
              class="text-no-result"
              key="empty"
            >
              - You have not added any people -
            </div>
            <div
              key="list"
              v-else
            >
              <transition-group
                name="component-fade"
                mode="out-in"
              >
                <item-person
                  v-for="(item, index) in localFormatted.personalNotify.other"
                  :key="item + index"
                  class="mb-2"
                  :id="item"
                  :recommended="['phone', 'email']"
                  :editable="editable"
                  type="person"
                  @remove="removePersonToNotify(index)"
                >
                  <template v-slot:details="{ entity }">
                    <div
                      v-if="entity.type === 'person'"
                      class="text-sm text-cutoff opacity-70 py-1"
                    >
                      <span
                        v-if="!allRequiredFieldsComplete(entity)"
                        class="font-italic"
                        >- Information required -</span
                      >
                      <span v-else>
                        {{ entity.address }}
                      </span>
                    </div>
                  </template>
                </item-person>
              </transition-group>
            </div>
          </transition>
          <div class="mb-2">
            <btn-add-large
              label="Add person"
              class="mb-2"
              @click="openDrawerPerson = true"
              @clickDisabled="disabledMessage()"
              :disabled="isMaximumSelected"
            />
          </div>
          <v-input
            :error-messages="
              showErrors.otherPeople && errorMessages.otherPeople
                ? errorMessages.otherPeople
                : null
            "
          />
          <br />
        </div>
      </v-expand-transition>
    </item-checkbox>

    <div v-if="personsToNotify">
      <br />
      <h3 class="mb-4">What do you want to notify?</h3>

      <div class="mb-4">
        <item-checkbox
          :value="localFormatted.personalNotify.what.writtenNotice"
          @select="toggleWrittenNotice()"
          title="Written notice of intention to begin exercising power"
          text="My attorney(s) for personal (including health) matters must provide written notice that they intend to begin exercising power for a personal (including health) matter under this enduring power of attorney before exercising the power for the first time."
          class="mb-2"
        />
        <item-checkbox
          :value="showWhatOtherInfoTextbox"
          @select="toggleShowWhatOtherInfoTextbox"
          title="Other"
          text="Select this option to provide details about the information that must be provided, including how often your attorney(s) must provide the information."
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showWhatOtherInfoTextbox"
              class="mx-4"
            >
              <h3 class="my-4">
                Please specify the information to be provided:
              </h3>
              <other-personal-info-example />
              <v-textarea
                :rules="[maxLengthRule]"
                v-if="showWhatOtherInfoTextbox"
                v-bind="textAreaFieldGrow"
                v-model="local.personalNotify.what.otherInformation"
                :error-messages="
                  showErrors.other && errorMessages.other
                    ? errorMessages.other
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-checkbox>
        <v-input
          :error-messages="
            showErrors.what && errorMessages.what ? errorMessages.what : null
          "
        />
      </div>
    </div>

    <div class="d-flex align-center">
      <v-btn
        v-bind="backButton"
        @click="close"
        ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >{{ submitLabel }}</v-btn
      >
    </div>

    <drawer
      :open.sync="openDrawerPerson"
      title="Add person"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addPersonToNotify"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :recommended="['phone', 'email']"
        :mandatory="required"
      />
    </drawer>

    <drawer
      :open.sync="showDefinition"
      title="Explanation"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">You (the principal)</div>
        <p>
          While your attorney(s) must always keep you informed and seek your
          views about every decision they make, this instruction will require
          your attorney(s) to notify you in writing, even if you have lost
          capacity. Your capacity may change or fluctuate over time, and your
          attorney(s) have a duty to keep you informed about decisions they are
          making.
        </p>
        <br />
        <div class="text-lg font-weight-bold mb-4">
          The other current attorney(s) that you may have appointed
        </div>
        <p>
          If you have appointed multiple attorneys, you can require an
          attorney(s) to notify other current attorney(s) before they make a
          decision. A current attorney means any other attorney appointed for
          personal (including health) matters and who can also make decisions
          for you at the time the notification must be given.
        </p>
        <br />
        <div class="text-lg font-weight-bold mb-4">A person you nominate</div>
        <p>
          You can nominate a trusted person who your attorney(s) are required to
          notify. It may be a good idea to nominate a trusted person who is
          completely independent from your attorney(s) and who can check that
          your attorney(s) are making decisions in your best interests.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import { qld_epa_required } from '../../../../../../config/poaRequiredFields.js'
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils.js'
import OtherPersonalInfoExample from './components/OtherPersonalInfoExample'

const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'PersonalNotify',
  components: { OtherPersonalInfoExample },
  mixins: [forms(storeName, documentId), entitiesForms('', '')],
  data: () => ({
    showErrors: {
      other: false,
      otherPeople: false,
      what: false,
    },
    editable: { name: true, address: true, phone: true, email: true },
    required: qld_epa_required,
    showSelectPersons: false,
    showWhatOtherInfoTextbox: false,
    showDefinition: false,
  }),
  mounted() {
    this.showSelectPersons = this.local.personalNotify.other.length
    this.showWhatOtherInfoTextbox = this.local.personalNotify.what
      .otherInformation
      ? true
      : false
  },
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          personalNotify: {
            ...this.localFormatted.personalNotify,
            what: {
              writtenNotice:
                this.localFormatted.personalNotify.what.writtenNotice,
              otherInformation: trimIfExists(
                this.localFormatted.personalNotify.what.otherInformation
              ),
            },
          },
        },
      })
    },
    toggleShowWhatOtherInfoTextbox() {
      this.setAllShowErrors(false)
      this.showWhatOtherInfoTextbox = !this.showWhatOtherInfoTextbox
    },
    toggleWrittenNotice() {
      this.setAllShowErrors(false)
      this.local.personalNotify.what.writtenNotice =
        !this.local.personalNotify.what.writtenNotice
    },
    addPersonToNotify(id) {
      this.setAllShowErrors(false)
      this.local.personalNotify.other.push(id)
      this.openDrawerPerson = false
    },
    removePersonToNotify(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.personalNotify.other, index)
    },
    disabledMessage() {
      if (this.isMaximumSelected) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Maximum of 20 people.',
        })
        return
      }
    },
  },
  computed: {
    excludePeople() {
      const jointAttorneys =
        this.local.attorneys.length > 1 && this.local.appointment === 'jointly'
          ? this.local.attorneys
          : []
      return [...this.localFormatted.personalNotify.other, ...jointAttorneys]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.personalNotify.other.length > 20)
        msgs.other = 'Maximum of 20 people with benefits.'
      if (
        this.showSelectPersons &&
        this.localFormatted.personalNotify.other.length < 1
      )
        msgs.otherPeople =
          'You have selected to nominate people to notify. Please add them.'
      if (
        this.personsToNotify &&
        this.showWhatOtherInfoTextbox &&
        isEmptyTrimmed(this.localFormatted.personalNotify.what.otherInformation)
      )
        msgs.other =
          'Please specify the details about the information to be provided.'
      if (
        this.personsToNotify &&
        !(
          this.localFormatted.personalNotify.what.writtenNotice ||
          this.showWhatOtherInfoTextbox
        )
      )
        msgs.what = 'Please select what information must be provided.'
      return msgs
    },
    isMaximumSelected() {
      return this.localFormatted.personalNotify.other.length >= 20
    },
    personsToNotify() {
      return (
        this.local.personalNotify.me ||
        this.local.personalNotify.otherAttorneys ||
        (this.showSelectPersons && this.local.personalNotify.other.length)
      )
    },
    localFormatted() {
      return {
        personalNotify: {
          what: this.personsToNotify
            ? {
                writtenNotice: this.local.personalNotify.what.writtenNotice
                  ? true
                  : false,
                otherInformation: this.showWhatOtherInfoTextbox
                  ? this.local.personalNotify.what.otherInformation
                  : null,
              }
            : {
                writtenNotice: null,
                otherInformation: null,
              },
          me: this.local && this.local.personalNotify.me ? true : false,
          otherAttorneys:
            this.local &&
            (this.local.attorneys.length > 1 ||
              this.local.successives.length > 1) &&
            this.local.personalNotify.otherAttorneys
              ? true
              : false,
          other:
            this.showSelectPersons && this.local
              ? this.local.personalNotify.other
              : [],
        },
      }
    },
  },
}
</script>
