<template>
  <div>
    <p
      class="definition-link pr-4 pl-10 my-0 py-4"
      @click="showDefinition = true"
    >
      See examples
    </p>
    <drawer
      :open.sync="showDefinition"
      title="Examples"
      class="faq-drawer"
    >
      <div class="pa-4">
        <ul>
          <li>When I am admitted to hospital...</li>
          <li>When I am travelling overseas...</li>
        </ul>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'CommencementOtherExamples',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
