<template>
  <div>
    <span
      class="definition-link"
      @click="showDefinition = true"
    >
      What does it mean to vacate office?
    </span>
    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">Vacancy in the office</div>
        <p>
          A vacancy in the office of an attorney may arise for a number of
          reasons, including (but not limited to) if the attorney:
        </p>
        <ol style="list-style: lower-alpha">
          <li>dies;</li>
          <li>resigns;</li>
          <li>
            does not have capacity for the matter for which they have been
            appointed; or
          </li>
          <li>
            is declared bankrupt (to the extent that the attorney has been given
            power for financial matters).
          </li>
        </ol>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'VacancyDefinition',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
