<template>
  <div class="d-inline-block">
    <div
      class="definition-link mb-4"
      @click="showDefinition = true"
    >
      Examples
    </div>
    <drawer
      :open.sync="showDefinition"
      title="Examples"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div
          class="mb-4"
          v-if="personalMatters"
        >
          <p><b>For personal (including health) matters</b></p>
          <ol>
            <li class="mb-4">
              My attorney can make all decisions about personal matters except
              for decisions about friends and family members with whom I have
              contact.
            </li>
            <li class="mb-4">
              I do not consent to my children or their families living in my
              home, with or without me.
            </li>
          </ol>
        </div>
        <div
          class="mb-4"
          v-if="financialMatters"
        >
          <br />
          <p><b>For financial matters</b></p>
          <ol>
            <li class="mb-4">
              My attorney is not to sell my house unless my attorney has
              exhausted all other options to pay for my aged care accommodation
              and services.
            </li>
            <li>My attorney must not make any investments with my money.</li>
          </ol>
        </div>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'TermsExamples',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
  props: {
    personalMatters: {
      type: Boolean,
      required: true,
    },
    financialMatters: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
