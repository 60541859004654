<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h2 class="mb-4">What is your full name?</h2>
        <notification-box
          type="warning"
          class="mb-6"
          v-if="!editable"
          :flat="true"
        >
          <div class="mb-2">
            You can't change the name of the account once it has been purchased.
            If there is a spelling mistake or your name has changed please
            contact our support for assistance on <b>1300 945 533</b>.
          </div>
        </notification-box>
        <p>
          Enter your first, middle and last names. If you don't have any middle
          names, leave that field blank.
        </p>
        <v-text-field
          v-bind="textField"
          label="First name"
          v-model="local.name.first"
          :error-messages="
            showErrors.first && errorMessages.first ? errorMessages.first : null
          "
          @input="showErrorsSwitch(true, 'first')"
          @blur="showErrorsSwitch(true, 'first')"
          :disabled="!editable"
        />
        <v-text-field
          v-bind="textField"
          label="Middle name"
          v-model="local.name.middle"
          :error-messages="
            showErrors.middle && errorMessages.middle
              ? errorMessages.middle
              : null
          "
          @input="showErrorsSwitch(true, 'middle')"
          @blur="showErrorsSwitch(true, 'middle')"
          :disabled="!editable"
        />
        <v-text-field
          v-bind="textField"
          label="Last name"
          v-model="local.name.last"
          :error-messages="
            showErrors.last && errorMessages.last ? errorMessages.last : null
          "
          @input="showErrorsSwitch(true, 'last')"
          @blur="showErrorsSwitch(true, 'last')"
          :disabled="!editable"
        />
      </div>

      <div class="mb-4">
        <h2 class="mb-4">What is your phone number?</h2>
        <v-text-field
          v-bind="textField"
          placeholder="eg 0400123456"
          hint="10 digit mobile or landline"
          v-model="local.phone"
          :error-messages="
            showErrors.phone && errorMessages.phone ? errorMessages.phone : null
          "
          @blur="showErrorsSwitch(true, 'phone')"
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import { mapGetters } from 'vuex'

import NotificationBox from '../../../../components/ui/NotificationBox'
export default {
  name: 'WillFormAboutName',
  components: { NotificationBox },
  props: {
    closeLabel: {
      default: 'back',
    },
    submitLabel: {
      default: 'save',
    },
    accessStatus: {
      default: null,
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      first: false,
      middle: false,
      last: false,
      phone: false,
    },
    processing: false,
  }),
  mounted() {
    this.local = {
      name: cloneDeep(this.user_name.value),
      phone: cloneDeep(this.user_phone.value),
    }
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch('account/save_user_data', this.localFormatted)
      if (this.$store.getters['account/user_profile_complete']) {
        let sections = ['about']
        if (this.$store.getters['account/user_partner'].isComplete) {
          sections.push('partner')
        }
        await this.$store.dispatch('account/update_will_progress', sections)
      }
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
  computed: {
    ...mapGetters('account', ['user_name', 'user_phone']),
    editable() {
      if (this.accessStatus === 'unactivated' || !this.user_name.isComplete) {
        return true
      }

      return false
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        first: null,
        middle: null,
        last: null,
        phone: null,
      }

      if (!this.localFormatted.name.first) {
        msgs.first = 'Required field'
      }
      if (!this.localFormatted.name.last) {
        msgs.last = 'Required field'
      }

      if (
        !msgs.first &&
        !/^[A-Za-z\- ‘’'`]+$/.test(this.localFormatted.name.first)
      ) {
        msgs.first = 'Invalid character entered'
      }
      if (
        this.localFormatted.name.middle &&
        !/^[A-Za-z\- ‘’'`]+$/.test(this.localFormatted.name.middle)
      ) {
        msgs.middle = 'Invalid character entered'
      }
      if (
        !msgs.last &&
        !/^[A-Za-z\- ‘’'`]+$/.test(this.localFormatted.name.last)
      ) {
        msgs.last = 'Invalid character entered'
      }

      if (!msgs.first && this.localFormatted.name.first.length > 64) {
        msgs.first = 'Maximum length exceeded'
      }
      if (!msgs.last && this.localFormatted.name.last.length > 64) {
        msgs.last = 'Maximum length exceeded'
      }
      if (!msgs.middle && this.localFormatted.name.middle.length > 64) {
        msgs.middle = 'Maximum length exceeded'
      }

      if (!/^[0-9]*$/.test(this.localFormatted.phone)) {
        msgs.phone = 'Should only contain numbers (no spaces)'
      }

      if (!msgs.phone && ![0, 10].includes(this.localFormatted.phone.length)) {
        msgs.phone = 'Must be 10 digits long'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, {
        name: this.user_name.value,
        phone: this.user_phone.value,
      })
    },
    localFormatted() {
      return {
        name: {
          first: this.local.name.first.trim().replace(/\s\s+/g, ' '),
          middle: this.local.name.middle.trim().replace(/\s\s+/g, ' '),
          last: this.local.name.last.trim().replace(/\s\s+/g, ' '),
        },
        phone: this.local.phone.replace(/\s+/g, ''),
      }
    },
  },
}
</script>
