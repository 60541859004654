<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Terms and instructions for your attorney(s)
        <span class="optional">(optional)</span>
      </h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Information</div>
          <p>
            This part can be used to set terms or limits on an attorney's power
            and/or to give specific instructions that your attorney(s) must
            follow.
          </p>
          <p v-if="local.personalMatters && local.financialMatters">
            Terms, limits and/or instructions can be included for personal
            (including health) matters and/or financial matters.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to set terms or limits on your attorney{{
          attorneyCombinedPossessiveSuffix
        }}
        power and/or give specific instructions that your attorney{{
          attorneyCombinedSuffix
        }}
        must follow?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="setShowTextbox(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="setShowTextbox(false)"
          title="No"
        />
      </div>

      <terms-examples
        v-if="showTextbox"
        :personal-matters="local.personalMatters"
        :financial-matters="local.financialMatters"
      />

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.terms"
        :error-messages="
          showErrors.terms && errorMessages.terms ? errorMessages.terms : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'
import TermsExamples from './components/TermsExamples'
const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'PersonalTerms',
  components: { TermsExamples },
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      terms: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.terms
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          terms: trimIfExists(this.localFormatted.terms),
        },
      })
    },
  },
  computed: {
    attorneyCombinedSuffix() {
      return this.local.attorneys.length + this.local.successives.length > 1
        ? `s`
        : ``
    },
    attorneyCombinedPossessiveSuffix() {
      return this.local.attorneys.length + this.local.successives.length > 1
        ? `s'`
        : `'s`
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.terms)) {
        msgs.terms = 'Required'
      }
      return msgs
    },
    localFormatted() {
      return {
        terms: this.showTextbox ? this.local.terms : null,
      }
    },
  },
}
</script>
