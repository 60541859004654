<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Substitute attorneys <span class="optional">(optional)</span>
      </h2>
      <h3 class="mb-4">Would you like to appoint substitute attorney(s)?</h3>
      <item-select
        :value="appointSubstitutes === false"
        @select="selecteSubstitutesStyle('none'), (appointSubstitutes = false)"
        title="No"
        text="I don't want to appoint any substitute attorney(s)."
        class="mb-2"
      />
      <item-select
        :value="appointSubstitutes === true"
        @select="selectYesSubstitutes()"
        title="Yes"
        :text="
          local.appointmentTermination === 'terminated'
            ? 'I want to appoint substitute attorney(s) to act if one of the attorneys dies, resigns or otherwise vacates office.'
            : 'I want to appoint substitute attorney(s).'
        "
        class="mb-2"
      />
      <v-input
        :error-messages="
          showErrors.substitutesStyle && errorMessages.substitutesStyle
            ? errorMessages.substitutesStyle
            : null
        "
      />
      <div
        v-if="
          appointSubstitutes &&
          attorneys.length > 1 &&
          ((local.appointment === 'jointly' &&
            local.appointmentTermination === 'not-terminated') ||
            local.appointment === 'jointly-severally')
        "
      >
        <h3 class="mb-4">
          When would you like your substitute attorney(s) to act?
        </h3>
        <item-select
          :value="localFormatted.substitutesStyle === 'general'"
          @select="selecteSubstitutesStyle('general')"
          title="Option 1"
          text="I want my substitute attorney(s) to act if all of the attorneys have died, resigned or otherwise vacated office."
          class="mb-2"
        />
        <item-select
          :value="localFormatted.substitutesStyle === 'per-primary'"
          @select="selecteSubstitutesStyle('per-primary')"
          title="Option 2"
          text="I want my substitute attorney(s) to act in place of specific attorneys who have died, resigned or otherwise vacated office."
          class="mb-2"
        />
        <span
          class="definition-link"
          @click="showDefinition = true"
        >
          What does it mean to vacate office?
        </span>
        <br />
        <br />
      </div>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <!-- general -->
        <div v-if="localFormatted.substitutesStyle === 'general'">
          <h3 class="mb-4">Your substitute attorney(s):</h3>
          <transition
            name="component-fade"
            mode="out-in"
          >
            <div
              v-if="!localFormatted.substitutes.length"
              class="text-no-result"
              key="empty"
            >
              - You have not added any substitute attorneys -
            </div>
            <div
              key="list"
              v-else
            >
              <transition-group
                name="component-fade"
                mode="out-in"
              >
                <item-person
                  v-for="(item, index) in localFormatted.substitutes"
                  :key="item + index"
                  class="mb-2"
                  :id="item.entity"
                  :editable="editable"
                  type="person"
                  @remove="removeSubstituteAttorney(index)"
                >
                  <template v-slot:details="{ entity }">
                    <div
                      v-if="entity.type === 'person'"
                      class="text-sm text-cutoff opacity-70 py-1"
                    >
                      <span
                        v-if="!entity.address"
                        class="font-italic"
                        >- Address Required -</span
                      >
                      <span v-else>
                        {{ entity.address }}
                      </span>
                    </div>
                  </template>
                </item-person>
              </transition-group>
            </div>
          </transition>
          <div class="text-label my-4">Add a substitute attorney:</div>
          <div class="mb-2">
            <btn-add-large
              label="Add substitute (optional)"
              class="mb-2"
              @click=";(openDrawerPerson = true), (addInSubstitutionOf = null)"
              @clickDisabled="disabledMessage()"
              :disabled="isMaximumSelected"
            />
          </div>
          <v-input
            :error-messages="
              showErrors.generalSubstitutes && errorMessages.generalSubstitutes
                ? errorMessages.generalSubstitutes
                : null
            "
          />
          <br />
          <br />
        </div>

        <!-- per-primary -->
        <div v-else-if="localFormatted.substitutesStyle === 'per-primary'">
          <div
            v-for="(primary, index) in attorneys"
            :key="primary.id"
            :class="{ 'mb-16': index + 1 < attorneys.length }"
          >
            <h3 class="mb-4">
              Your substitute attorney(s) for {{ primary.name }}:
            </h3>
            <transition
              name="component-fade"
              mode="out-in"
            >
              <div
                v-if="
                  !localFormatted.substitutes.filter(
                    (s) => s.inSubstitutionOf === primary.id
                  ).length
                "
                class="text-no-result"
                key="empty"
              >
                - You have not added any substitute attorneys for
                {{ primary.name }} -
              </div>
              <div
                key="list"
                v-else
              >
                <transition-group
                  name="component-fade"
                  mode="out-in"
                >
                  <item-person
                    v-for="(item, index) in localFormatted.substitutes.filter(
                      (s) => s.inSubstitutionOf === primary.id
                    )"
                    :key="item + index"
                    class="mb-2"
                    :id="item.entity"
                    :editable="editable"
                    type="person"
                    @remove="removePerPrimaryAttorney(item.entity)"
                  >
                    <template v-slot:details="{ entity }">
                      <div
                        v-if="entity.type === 'person'"
                        class="text-sm text-cutoff opacity-70 py-1"
                      >
                        <span
                          v-if="!entity.address"
                          class="font-italic"
                          >- Address Required -</span
                        >
                        <span v-else>
                          {{ entity.address }}
                        </span>
                      </div>
                    </template>
                  </item-person>
                </transition-group>
              </div>
            </transition>
            <div class="text-label my-4">
              Add a substitute attorney for {{ primary.name }}:
            </div>
            <div class="mb-2">
              <btn-add-large
                :label="`Add substitute (optional)`"
                class="mb-2"
                @click="
                  ;(openDrawerPerson = true), (addInSubstitutionOf = primary.id)
                "
                @clickDisabled="disabledMessage()"
                :disabled="
                  localFormatted.substitutes.filter(
                    (s) => s.inSubstitutionOf === primary.id
                  ).length > 3
                "
              />
            </div>
          </div>
          <v-input
            class="mb-2"
            :error-messages="
              showErrors.perPrimarySubstitutes &&
              errorMessages.perPrimarySubstitutes
                ? errorMessages.perPrimarySubstitutes
                : null
            "
          />
        </div>
      </transition>

      <v-expand-transition>
        <div
          v-if="
            this.localFormatted.substitutesStyle === 'general' &&
            this.localFormatted.substitutes.length > 1
          "
          class="mb-4"
        >
          <h3 class="mb-4">
            How would you like your substitute attorneys to work together?
          </h3>
          <item-select
            v-for="(item, index) in appointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.substituteAppointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < appointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.substituteAppointment &&
              errorMessages.substituteAppointment
                ? errorMessages.substituteAppointment
                : null
            "
          />
          <transition
            name="component-fade"
            mode="out-in"
          >
            <div v-if="localFormatted.substituteAppointment === 'jointly'">
              <br />
              <br />
              <h3 class="mb-4">
                Would you like the appointment of all substitute attorneys to be
                terminated if any one of them dies?
              </h3>
              <item-select
                v-for="(item, index) in substituteAppointmentTerminationOptions"
                :key="item.key"
                :value="
                  item.key === localFormatted.substituteAppointmentTermination
                "
                @select="selectAppointmentTerminationOption(item.key)"
                :title="item.label"
                :text="item.text"
                :class="{
                  'mb-2':
                    index + 1 < substituteAppointmentTerminationOptions.length,
                }"
              />
              <v-input
                :error-messages="
                  showErrors.appointmentTermination &&
                  errorMessages.appointmentTermination
                    ? errorMessages.appointmentTermination
                    : null
                "
              />
            </div>
          </transition>
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addSubstituteAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
    <!-- Also change in AppointAttorneys.vue -->
    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">Vacancy in the office</div>
        <p>Section 5 of the <i>Powers of Attorney Act 2003</i></p>
        <p>
          For the purposes of this Act, there is a vacancy in the office of an
          attorney if:
        </p>
        <ol style="list-style: lower-alpha">
          <li>the appointment of the attorney is revoked, or</li>
          <li>the attorney renounces the power, or</li>
          <li>the attorney dies, or</li>
          <li>the attorney becomes bankrupt, or</li>
          <li>
            where the attorney is a corporation, the corporation is dissolved,
            or
          </li>
          <li>
            the attorney, by reason of any physical or mental incapacity, ceases
            to have the capacity to continue to act as an attorney, or
          </li>
          <li>
            in such other circumstances as may be prescribed by the regulations
            for the purposes of this paragraph.
          </li>
        </ol>
      </div>
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import {
  substituteAppointmentOptions,
  substituteAppointmentTerminationOptions,
} from '../../epaLists'

const storeName = 'poa_nsw_epa'
const documentId = 'poaNswEpa'

export default {
  name: 'SubstituteAttorneys',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('substitutes', 'Maximum of 4 substitute attorneys.'),
  ],
  data: () => ({
    showErrors: {
      substitutesStyle: false,
      generalSubstitutes: false,
      substituteAppointment: false,
      appointmentTermination: false,
      perPrimarySubstitutes: false,
    },
    showDefinition: false,
    editable: { address: true },
    appointmentOptions: substituteAppointmentOptions,
    substituteAppointmentTerminationOptions:
      substituteAppointmentTerminationOptions,
    addInSubstitutionOf: null,
    appointSubstitutes: false,
  }),
  mounted() {
    this.appointSubstitutes =
      this.local.substitutesStyle === 'general' ||
      this.local.substitutesStyle === 'per-primary'
  },
  methods: {
    selectYesSubstitutes() {
      this.appointSubstitutes = true
      if (
        !(
          this.attorneys.length > 1 &&
          ((this.local.appointment === 'jointly' &&
            this.local.appointmentTermination === 'not-terminated') ||
            this.local.appointment === 'jointly-severally')
        )
      )
        this.local.substitutesStyle = 'general'
    },
    addSubstituteAttorney(id) {
      this.local.substitutes.push({
        entity: id,
        inSubstitutionOf: this.addInSubstitutionOf,
      })
      this.openDrawerPerson = false
    },
    removeSubstituteAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.substitutes, index)
    },
    removePerPrimaryAttorney(entityId) {
      this.setAllShowErrors(false)
      this.local.substitutes = this.local.substitutes.filter(
        (s) => s.entity !== entityId
      )
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.substituteAppointment = option
    },
    selecteSubstitutesStyle(option) {
      this.setAllShowErrors(false)
      this.local.substitutesStyle = option
    },
    selectAppointmentTerminationOption(option) {
      this.setAllShowErrors(false)
      this.local.substituteAppointmentTermination = option
    },
    substituteFilter(substitute) {
      if (substitute.inSubstitutionOf) return true
      return this.local.substitutesStyle !== 'per-primary'
    },
  },
  computed: {
    attorneys() {
      return this[storeName].attorneys.map((a) =>
        this.entities_people.find((o) => o.id === a)
      )
    },
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.substitutes.map((s) => s.entity),
        ...this.local.attorneys,
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.substitutesStyle)
        msgs.substitutesStyle = 'Required'
      if (this.localFormatted.substitutesStyle === 'general') {
        if (this.localFormatted.substitutes.length < 1)
          msgs.generalSubstitutes =
            'You must add at least one substitute attorney.'
        if (
          this.localFormatted.substitutes.length > 1 &&
          !this.localFormatted.substituteAppointment
        )
          msgs.substituteAppointment = 'Required'
        if (
          this.localFormatted.substituteAppointment === 'jointly' &&
          !this.localFormatted.substituteAppointmentTermination
        )
          msgs.appointmentTermination = 'Required'
      } else if (
        this.localFormatted.substitutesStyle === 'per-primary' &&
        this.localFormatted.substitutes.filter((s) => s.inSubstitutionOf)
          .length < 1
      )
        msgs.perPrimarySubstitutes =
          'You must add at least one substitute attorney.'
      return msgs
    },
    localFormatted() {
      let substituteAppointment = this.local.substituteAppointment || null
      let appointmentOption = substituteAppointment
        ? this.appointmentOptions.find(
            (option) => option.key === substituteAppointment
          ) || null
        : null
      let appointmentTermination =
        this.local?.substituteAppointmentTermination || null
      let appointmentTerminationOption = appointmentTermination
        ? this.substituteAppointmentTerminationOptions.find(
            (option) => option.key === appointmentTermination
          ) || null
        : null
      return {
        substitutesStyle: this.local.substitutesStyle,

        substitutes:
          this.local.substitutesStyle !== 'general' &&
          this.local.substitutesStyle !== 'per-primary'
            ? []
            : this.local.substitutes.filter(this.substituteFilter) || [],

        substituteAppointment:
          this.local.substitutes.length <= 1 ||
          this.local.substitutesStyle !== 'general'
            ? null
            : appointmentOption
              ? substituteAppointment
              : null,

        substituteAppointmentTermination:
          this.local.substitutes.length <= 1 ||
          this.local.substitutesStyle !== 'general'
            ? null
            : substituteAppointment !== 'jointly'
              ? null
              : appointmentTerminationOption
                ? appointmentTermination
                : null,
      }
    },
  },
}
</script>
