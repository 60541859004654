<template>
  <div class="d-inline-block">
    <span
      class="definition-link"
      @click="showDefinition = true"
    >
      health provider
    </span>
    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">Health provider</div>
        <p>
          Your health provider is a person who provides you with health care as
          a professional or in the ordinary course of business such as your
          doctor, dentist or nurse.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'HealthProviderDefinition',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
