<template>
  <div>
    <div>
      <section-item
        @edit="open('matters')"
        class="mb-1"
      >
        <div class="text-label">Matters</div>
        <div v-if="local.personalMatters || local.financialMatters">
          <ul>
            <li v-if="local.personalMatters">
              Personal (including health) matters
            </li>
            <li v-if="local.financialMatters">Financial matters</li>
          </ul>
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('matters')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify the matters about which you are
            appointing your attorneys to make decisions.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('attorneys')"
        class="mb-1"
      >
        <div class="text-label">Attorney(s)</div>
        <ul v-if="attorneys.length">
          <li
            v-for="item in attorneys"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('attorneys')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least one attorney.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('successives')"
        :disabled="local.attorneys.length"
        @clickDisabled="showSuccessiveDisabledMsg = true"
        class="mb-1"
      >
        <div class="text-label">
          <p class="optional mb-1">(Optional)</p>
          Successive attorney(s)
        </div>
        <ul v-if="successives.length">
          <li
            v-for="item in successives"
            :key="item.id"
            class="mb-1"
          >
            {{ item.entity.name }}
            {{
              item.succeeds && local.successivesStyle === 'per-primary'
                ? `as successive attorney for ${item.succeeds.name}`
                : ''
            }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item.entity)"
              text="Information required"
              @click="openEditPerson(item.entity.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally add successive attorney(s).
          </div>
        </div>
      </section-item>
      <transition
        name="component-fade"
        mode="out-in"
      >
        <v-input
          class="-mt-2 mb-2"
          v-if="showSuccessiveDisabledMsg && !local.attorneys.length"
          :error-messages="
            showSuccessiveDisabledMsg && !local.attorneys.length
              ? `You must add at least 1 attorney before
        adding successive attorneys.`
              : null
          "
        />
      </transition>

      <section-item
        v-if="local.financialMatters"
        @edit="open('commencement')"
        class="mb-1"
      >
        <div class="text-label">Commencement for financial matters</div>
        <div v-if="local.commencement">
          {{ commencementSummary }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('commencement')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify when your attorney{{
              attorneysCombinedPossessiveSuffix
            }}
            powers are to begin.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('views-wishes-preferences')"
        class="mb-1"
      >
        <div class="text-label">
          <p class="optional mb-1">(Optional)</p>
          Views, wishes and preferences
        </div>
        <div
          v-if="!local.wishes"
          class="text-no-result text-left"
        >
          You have not added any views, wishes and preferences. Click 'Edit' on
          the right to optionally add some.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.wishes }}
        </div>
      </section-item>

      <section-item
        @edit="open('personal-terms')"
        class="mb-1"
      >
        <div class="text-label">
          <p class="optional mb-1">(Optional)</p>
          Terms and instructions for your attorney{{ attorneysCombinedSuffix }}
        </div>
        <div
          v-if="!local.terms"
          class="text-no-result text-left"
        >
          You have not added any terms or instructions. Click 'Edit' on the
          right to optionally add some.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.terms }}
        </div>
      </section-item>

      <section-item
        v-if="local.personalMatters"
        @edit="open('personal-notify')"
        class="mb-1"
      >
        <div class="text-label">
          <p class="optional mb-1">(Optional)</p>
          Notifications for personal (inc. health) matters
        </div>
        <div
          v-if="
            !personalNotifyOther.length &&
            !local.personalNotify.me &&
            !(
              (this.local.attorneys.length > 1 ||
                this.local.successives.length > 1) &&
              local.personalNotify.otherAttorneys
            )
          "
          class="text-no-result text-left"
        >
          You have not selected anyone to be notified. Click 'Edit' on the right
          to optionally add someone.
        </div>
        <div v-else>
          <div>Who:</div>
          <ul class="mt-2">
            <li v-if="local.personalNotify.me">Me (the principal)</li>
            <li
              v-if="
                (this.local.attorneys.length > 1 ||
                  this.local.successives.length > 1) &&
                local.personalNotify.otherAttorneys
              "
              class="mb-1"
            >
              My other current attorney(s)
            </li>
            <li
              v-if="personalNotifyOther.length"
              class="mb-1"
            >
              Nominated
              {{ personalNotifyOther.length === 1 ? ' person' : ' persons' }}:
              <ul class="mt-2">
                <li
                  v-for="item in personalNotifyOther"
                  :key="item.id"
                  class="mb-1"
                >
                  {{ item.name }}
                  <chip-required
                    v-if="!allRequiredFieldsComplete(item)"
                    text="Information required"
                    @click="openEditPerson(item.id)"
                    :class="{ shake: shake }"
                  />
                </li>
              </ul>
            </li>
          </ul>
          <br />
          <div>What:</div>
          <ul
            v-if="personalNotifyWhat.length"
            class="mt-2"
          >
            <li
              v-for="(item, index) in personalNotifyWhat"
              :key="index"
              class="mb-1"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </section-item>

      <section-item
        v-if="local.financialMatters"
        @edit="open('financial-notify')"
        class="mb-1"
      >
        <div class="text-label">
          <p class="optional mb-1">(Optional)</p>
          Notifications for financial matters
        </div>
        <div
          v-if="
            local.financialNotify.me ||
            local.financialNotify.otherAttorneys ||
            local.financialNotify.other.length
          "
        >
          <div>Who:</div>
          <ul class="mt-2">
            <li v-if="local.financialNotify.me">Me (the principal)</li>
            <li
              v-if="
                (this.local.attorneys.length > 1 ||
                  this.local.successives.length > 1) &&
                local.financialNotify.otherAttorneys
              "
            >
              My other current attorney(s)
            </li>
            <li v-if="financialNotifyOther.length">
              Nominated
              {{ financialNotifyOther.length === 1 ? ' person' : ' persons' }}:
              <ul class="mt-2">
                <li
                  v-for="item in financialNotifyOther"
                  :key="item.id"
                  class="mb-1"
                >
                  {{ item.name }}
                  <chip-required
                    v-if="!allRequiredFieldsComplete(item)"
                    text="Information required"
                    @click="openEditPerson(item.id)"
                    :class="{ shake: shake }"
                  />
                </li>
              </ul>
            </li>
          </ul>
          <br />
          <div>What:</div>
          <ul
            v-if="financialNotifyWhat.length"
            class="mt-2"
          >
            <li
              v-for="(item, index) in financialNotifyWhat"
              :key="index"
              class="mb-1"
            >
              {{ item }}
            </li>
          </ul>
          <br />
          <div>When:</div>
          <ul
            v-if="financialNotifyWhen.length"
            class="mt-2"
          >
            <li
              v-for="(item, index) in financialNotifyWhen"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div
          v-if="
            !financialNotifyOther.length &&
            !local.financialNotify.me &&
            !local.financialNotify.otherAttorneys
          "
          class="text-no-result text-left"
        >
          You have not selected anyone to be notified. Click 'Edit' on the right
          to optionally add someone.
        </div>
      </section-item>

      <section-item
        @edit="open('signature-assistance')"
        class="mb-1"
      >
        <div class="text-label">
          <p class="optional mb-1">(Optional)</p>
          Assistance with signing
        </div>
        <div
          v-if="!local.signatureAssistance"
          class="text-no-result text-left"
        >
          If you are physically unable to sign your Enduring Power of Attorney,
          click 'Edit' on the right to specify that another person is to sign on
          your behalf.
        </div>
        <div v-else>
          You have specified that you require another person to sign on your
          behalf as you are physically unable to sign the document yourself.
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Attorney"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { qld_epa_required } from '../../../../config/poaRequiredFields'
import poa from '../mixins/poa.js'
import { formatToAusCurrency } from '../utils'
import DialogAttorneys from './forms/epa/Attorneys.vue'
import DialogCommencement from './forms/epa/Commencement.vue'
import DialogFinancialNotify from './forms/epa/FinancialNotify.vue'
import DialogMatters from './forms/epa/Matters.vue'
import DialogPersonalNotify from './forms/epa/PersonalNotify.vue'
import DialogPersonalTerms from './forms/epa/PersonalTerms.vue'
import DialogSignatureAssistance from './forms/epa/SignatureAssistance.vue'
import DialogSuccessives from './forms/epa/Successives.vue'
import DialogViewsWishesPreferences from './forms/epa/ViewsWishesPreferences.vue'

const storeName = 'poa_qld_epa'
const documentDir = 'qldEpa'

function truncateString(str) {
  if (!str) return ''
  return str.length <= 40 ? str : str.slice(0, 40) + '...'
}

export default {
  name: 'PoaEnduringPowerOfAttorney',
  mixins: [poa(storeName)],
  components: {
    DialogSuccessives,
    DialogCommencement,
    DialogMatters,
    DialogFinancialNotify,
    DialogAttorneys,
    DialogPersonalNotify,
    DialogPersonalTerms,
    DialogViewsWishesPreferences,
    DialogSignatureAssistance,
  },
  data: () => ({
    editable: { address: true, phone: true, email: true },
    required: qld_epa_required,
    documentDir: documentDir,
    showSuccessiveDisabledMsg: false,
  }),
  methods: {},
  computed: {
    attorneys() {
      return this[storeName].attorneys
        .map((id) => this.entities_people.find((o) => o.id === id))
        .filter(Boolean)
    },
    successives() {
      return this.local.successivesStyle === 'per-primary'
        ? this.attorneys
            .flatMap((a) =>
              this[storeName].successives.filter((s) => s.succeeds === a.id)
            )
            .map((s) => ({
              ...s,
              entity: this.entities_people.find((o) => o.id === s.entity),
              succeeds: this.entities_people.find((o) => o.id === s.succeeds),
            }))
        : this[storeName].successives.map((s) => ({
            ...s,
            entity: this.entities_people.find((o) => o.id === s.entity),
          }))
    },
    attorneySuffix() {
      return this.attorneys.length > 1 ? 's' : ''
    },
    attorneysCombinedSuffix() {
      return this.attorneys.length + this.successives.length > 1 ? `s` : ``
    },

    attorneysCombinedPossessiveSuffix() {
      return this.attorneys.length + this.successives.length > 1 ? `s'` : `'s`
    },
    personalNotifyOther() {
      return this[storeName].personalNotify.other.map((id) =>
        this.entities_people.find((o) => o.id === id)
      )
    },
    personalNotifyWhat() {
      let notify = []
      if (this[storeName].personalNotify.what.writtenNotice)
        notify.push('Written notice of intention to begin exercising power')
      if (this[storeName].personalNotify.what.otherInformation)
        notify.push(
          `Other information: ${truncateString(
            this[storeName].personalNotify.what.otherInformation
          )}`
        )
      return notify
    },
    financialNotifyOther() {
      return this[storeName].financialNotify.other.map((id) =>
        this.entities_people.find((o) => o.id === id)
      )
    },
    financialNotifyWhat() {
      let notify = []
      if (this[storeName].financialNotify.what.writtenNotice)
        notify.push('Written notice of intention to begin exercising power')
      if (this[storeName].financialNotify.what.allRecords)
        notify.push('All financial records and accounts')
      if (this[storeName].financialNotify.what.aboveAmount)
        notify.push(
          `Records relating to transactions above ${formatToAusCurrency(
            this[storeName].financialNotify.what.aboveAmount
          )}`
        )
      if (this[storeName].financialNotify.what.assets)
        notify.push('Records and accounts for all assets')
      if (this[storeName].financialNotify.what.summaries)
        notify.push('Summaries of income, expenditure and assets')
      if (this[storeName].financialNotify.what.plansAndAdvice)
        notify.push(
          'Copies of financial management plans and financial advice obtained'
        )
      if (this[storeName].financialNotify.what.otherInformation)
        notify.push(
          `Other information: ${truncateString(
            this[storeName].financialNotify.what.otherInformation
          )}`
        )
      return notify
    },
    financialNotifyWhen() {
      let notify = []
      if (this[storeName].financialNotify.when.onRequest)
        notify.push('On request at any time by the person(s) to be notified')
      if (this[storeName].financialNotify.when.onTimeframe)
        notify.push(`On a regular timeframe`)
      if (this[storeName].financialNotify.when.onOther)
        notify.push(
          `Other time: ${truncateString(this[storeName].financialNotify.when.onOther)}`
        )
      return notify
    },
    commencementSummary() {
      if (this.local.commencement === 'lack-capacity')
        return `When I do not have capacity to make decisions for financial matters`
      if (this.local.commencement === 'immediately') return `Immediately`
      if (this.local.commencement === 'other')
        return this.local.commencementOther
      return ``
    },
  },
}
</script>
