var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('div',{staticClass:"mb-4"},[_c('h2',{staticClass:"mb-4"},[_vm._v("What is your current residential address?")]),_c('p',[_vm._v(" You must live in Australia to make a Will or Enduring Power of Attorney with Willed. ")]),_c('address-picker',_vm._b({attrs:{"inline-label":true,"only-au":true,"input-type":"object","label":"Address","error-messages":_vm.showErrors.street && _vm.errorMessages.street
            ? _vm.errorMessages.street
            : null},on:{"input":function($event){return _vm.showErrorsSwitch(true, 'street')},"blur":function($event){return _vm.showErrorsSwitch(true, 'street')}},model:{value:(_vm.local.address),callback:function ($$v) {_vm.$set(_vm.local, "address", $$v)},expression:"local.address"}},'address-picker',_vm.textField,false)),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pr-4 flex-grow-1"},[_c('v-text-field',_vm._b({attrs:{"label":"Suburb","error-messages":_vm.showErrors.suburb && _vm.errorMessages.suburb
                ? _vm.errorMessages.suburb
                : null},on:{"input":function($event){return _vm.showErrorsSwitch(true, 'suburb')},"blur":function($event){return _vm.showErrorsSwitch(true, 'suburb')}},model:{value:(_vm.local.address.suburb),callback:function ($$v) {_vm.$set(_vm.local.address, "suburb", $$v)},expression:"local.address.suburb"}},'v-text-field',_vm.textField,false))],1),_c('div',{staticStyle:{"max-width":"30%"}},[_c('v-text-field',_vm._b({attrs:{"label":"Postcode","error-messages":_vm.showErrors.postcode && _vm.errorMessages.postcode
                ? _vm.errorMessages.postcode
                : null},on:{"input":function($event){return _vm.showErrorsSwitch(true, 'postcode')},"blur":function($event){return _vm.showErrorsSwitch(true, 'postcode')}},model:{value:(_vm.local.address.postcode),callback:function ($$v) {_vm.$set(_vm.local.address, "postcode", $$v)},expression:"local.address.postcode"}},'v-text-field',_vm.textField,false))],1)]),_c('v-select',_vm._b({attrs:{"label":"State","items":_vm.auStates,"return-object":"","error-messages":_vm.showErrors.state && _vm.errorMessages.state ? _vm.errorMessages.state : null},model:{value:(_vm.local.address.state),callback:function ($$v) {_vm.$set(_vm.local.address, "state", $$v)},expression:"local.address.state"}},'v-select',_vm.textField,false)),_c('v-select',_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"Country","disabled":"","items":_vm.countries,"error-messages":_vm.showErrors.country && _vm.errorMessages.country
            ? _vm.errorMessages.country
            : null},model:{value:(_vm.local.address.country),callback:function ($$v) {_vm.$set(_vm.local.address, "country", $$v)},expression:"local.address.country"}},'v-select',_vm.textField,false))],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)]):_vm._e(),_c('confirm-change-state',{attrs:{"showConfirmChangeState":_vm.showConfirmChangeState,"currentStateLabel":_vm.currentStateLabel},on:{"close":function($event){_vm.showConfirmChangeState = false},"confirmChangeState":_vm.confirmChangeState}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }