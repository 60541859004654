var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_vm._m(0),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Definition")]),_c('p',[_vm._v(" Successive attorneys only have power to act when the power given to an original attorney ends. ")]),_c('p',[_vm._v(" An attorney's power can end for a number of reasons. For example, the attorney may die, or lose capacity to make decisions for you due to illness or accident. ")])])]),_c('br'),_c('h3',{staticClass:"mb-4"},[_vm._v("Would you like to appoint successive attorney(s)?")]),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.appointSuccessives === false,"title":"No","text":"I don't want to appoint any successive attorney(s)."},on:{"select":function($event){_vm.selecteSuccessivesStyle('none'), (_vm.appointSuccessives = false)}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.appointSuccessives === true,"title":"Yes","text":"I want to appoint successive attorney(s)."},on:{"select":function($event){return _vm.selectYesSuccessives()}}}),_c('br'),(
        _vm.appointSuccessives &&
        _vm.attorneys.length > 1 &&
        _vm.local.appointment === 'jointly' &&
        _vm.local.appointmentTermination === 'not-terminated'
      )?_c('div',[_c('h3',{staticClass:"mb-4"},[_vm._v(" When would you like your successive attorney(s) to act? ")]),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.successivesStyle === 'general',"title":"Option 1","text":"I want my successive attorney(s) to act if all of the attorneys have died or lost capacity to make decisions for me."},on:{"select":function($event){return _vm.selecteSuccessivesStyle('general')}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.successivesStyle === 'per-primary',"title":"Option 2","text":"I want my successive attorney(s) to act jointly in place of specific attorneys who have died or lost capacity to make decisions for me."},on:{"select":function($event){return _vm.selecteSuccessivesStyle('per-primary')}}}),_c('v-input',{attrs:{"error-messages":_vm.showErrors.successivesStyle && _vm.errorMessages.successivesStyle
            ? _vm.errorMessages.successivesStyle
            : null}}),_c('br')],1):_vm._e(),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.localFormatted.successivesStyle === 'general')?_c('div',[_c('h3',{staticClass:"mb-4"},[_vm._v("Your successive attorney(s):")]),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(!_vm.localFormatted.successives.length)?_c('div',{key:"empty",staticClass:"text-no-result"},[_vm._v(" - You have not added any successive attorneys - ")]):_c('div',{key:"list"},[_c('transition-group',{attrs:{"name":"component-fade","mode":"out-in"}},_vm._l((_vm.localFormatted.successives),function(item,index){return _c('item-person',{key:item + index,staticClass:"mb-2",attrs:{"id":item.entity,"editable":_vm.editable,"recommended":['phone', 'email'],"type":"person"},on:{"remove":function($event){return _vm.removeSuccessiveAttorney(index)}},scopedSlots:_vm._u([{key:"details",fn:function({ entity }){return [(entity.type === 'person')?_c('div',{staticClass:"text-sm text-cutoff opacity-70 py-1"},[(!entity.address)?_c('span',{staticClass:"font-italic"},[_vm._v("- Address Required -")]):_c('span',[_vm._v(" "+_vm._s(entity.address)+" ")])]):_vm._e()]}}],null,true)})}),1)],1)]),_c('div',{staticClass:"text-label my-4"},[_vm._v("Add a successive attorney:")]),_c('div',{staticClass:"mb-2"},[_c('btn-add-large',{staticClass:"mb-2",attrs:{"label":"Add successive attorney (optional)","disabled":_vm.isMaximumSelected},on:{"click":function($event){;(_vm.openDrawerPerson = true), (_vm.selectedAttorney = null)},"clickDisabled":function($event){return _vm.disabledMessage()}}})],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.generalSuccessives && _vm.errorMessages.generalSuccessives
              ? _vm.errorMessages.generalSuccessives
              : null}}),_c('br'),_c('br')],1):(_vm.localFormatted.successivesStyle === 'per-primary')?_c('div',[_vm._l((_vm.attorneys),function(primary,index){return _c('div',{key:primary.id,class:{ 'mb-16': index + 1 < _vm.attorneys.length }},[_c('h3',{staticClass:"mb-4"},[_vm._v(" Your successive attorney(s) for "+_vm._s(primary.name)+": ")]),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(
                !_vm.localFormatted.successives.filter(
                  (s) => s.succeeds === primary.id
                ).length
              )?_c('div',{key:"empty",staticClass:"text-no-result"},[_vm._v(" - You have not added any successive attorneys for "+_vm._s(primary.name)+" - ")]):_c('div',{key:"list"},[_c('transition-group',{attrs:{"name":"component-fade","mode":"out-in"}},_vm._l((_vm.localFormatted.successives.filter(
                    (s) => s.succeeds === primary.id
                  )),function(item,index){return _c('item-person',{key:item + index,staticClass:"mb-2",attrs:{"id":item.entity,"editable":_vm.editable,"type":"person"},on:{"remove":function($event){return _vm.removePerPrimaryAttorney(item.entity)}},scopedSlots:_vm._u([{key:"details",fn:function({ entity }){return [(entity.type === 'person')?_c('div',{staticClass:"text-sm text-cutoff opacity-70 py-1"},[(!entity.address)?_c('span',{staticClass:"font-italic"},[_vm._v("- Address Required -")]):_c('span',[_vm._v(" "+_vm._s(entity.address)+" ")])]):_vm._e()]}}],null,true)})}),1)],1)]),_c('div',{staticClass:"text-label my-4"},[_vm._v(" Add a successive attorney for "+_vm._s(primary.name)+": ")]),_c('div',{staticClass:"mb-2"},[_c('btn-add-large',{staticClass:"mb-2",attrs:{"label":"Add successive attorney (optional)","disabled":_vm.localFormatted.successives.filter(
                  (s) => s.succeeds === primary.id
                ).length > 3},on:{"click":function($event){;(_vm.openDrawerPerson = true), (_vm.selectedAttorney = primary.id)},"clickDisabled":function($event){return _vm.disabledMessage()}}})],1)],1)}),_c('v-input',{staticClass:"mb-2",attrs:{"error-messages":_vm.showErrors.perPrimarySuccessives &&
            _vm.errorMessages.perPrimarySuccessives
              ? _vm.errorMessages.perPrimarySuccessives
              : null}})],2):_vm._e()]),_c('v-expand-transition',[(
          this.localFormatted.successivesStyle === 'general' &&
          this.localFormatted.successives.length > 1
        )?_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"mb-4"},[_vm._v(" How would you like your successive attorneys to work together? ")]),_vm._l((_vm.successiveAppointmentOptions),function(item,index){return _c('item-select',{key:item.key,class:{ 'mb-2': index + 1 < _vm.successiveAppointmentOptions.length },attrs:{"value":item.key === _vm.localFormatted.successiveAppointment,"title":item.label,"text":item.text},on:{"select":function($event){return _vm.selectSuccessiveAppointmentOption(item.key)}}})}),_c('v-input',{attrs:{"error-messages":_vm.showErrors.successiveAppointment &&
            _vm.errorMessages.successiveAppointment
              ? _vm.errorMessages.successiveAppointment
              : null}}),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.localFormatted.successiveAppointment === 'jointly')?_c('div',[_c('br'),_c('br'),_c('h3',{staticClass:"mb-4"},[_vm._v(" Would you like the appointment of all successive attorneys to be terminated if any one of them dies or loses capacity to make decisions for you? ")]),_vm._l((_vm.successiveAppointmentTerminationOptions),function(item,index){return _c('item-select',{key:item.key,class:{
                'mb-2':
                  index + 1 < _vm.successiveAppointmentTerminationOptions.length,
              },attrs:{"value":item.key === _vm.localFormatted.successiveAppointmentTermination,"title":item.label,"text":item.text},on:{"select":function($event){return _vm.selectAppointmentTerminationOption(item.key)}}})}),_c('v-input',{attrs:{"error-messages":_vm.showErrors.successiveAppointmentTermination &&
                _vm.errorMessages.successiveAppointmentTermination
                  ? _vm.errorMessages.successiveAppointmentTermination
                  : null}})],2):_vm._e()])],2):_vm._e()]),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e(),_c('drawer',{attrs:{"open":_vm.openDrawerPerson,"title":"Add attorney"},on:{"update:open":function($event){_vm.openDrawerPerson=$event}}},[(_vm.openDrawerPerson)?_c('drawer-person-select',{attrs:{"exclude":_vm.excludePeople,"editable":{ ..._vm.editable, name: false },"recommended":['phone', 'email'],"mandatory":['address']},on:{"select":_vm.addSuccessiveAttorney}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"mb-4"},[_vm._v(" Successive attorneys "),_c('span',{staticClass:"optional"},[_vm._v("(optional)")])])
}]

export { render, staticRenderFns }