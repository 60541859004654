<template>
  <div class="d-inline-block">
    <div
      class="definition-link mb-4"
      @click="showDefinition = true"
    >
      Examples
    </div>
    <drawer
      :open.sync="showDefinition"
      title="Examples"
      class="faq-drawer"
    >
      <div class="pa-4">
        <ol>
          <li class="mb-4">
            It is very important to me that I live in my own home. I do not want
            to reside in an aged care facility.
          </li>
          <li class="mb-4">
            I would like to remain in regular contact with my family and
            friends.
          </li>
        </ol>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'ViewsWishesPreferencesExamples',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
