<template>
  <div class="d-inline-block">
    <span
      class="definition-link"
      @click="showDefinition = true"
    >
      residential service
    </span>
    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">Residential service</div>
        <p>
          A residential service is rooming accommodation regulated under the
          <i>Residential Services (Accreditation) Act 2002</i>. A residential
          service is sometimes called a boarding house.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'ResidentialServiceDefinition',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
