<template>
  <div v-if="local">
    <h2 class="mb-4">
      Notifications for financial matters
      <span class="optional">(optional)</span>
    </h2>
    <notification-box
      type="info"
      class="mb-4"
      :flat="true"
    >
      <div class="py-1">
        <div class="font-weight-medium mb-2">Information</div>
        <p>
          This section can be used to require your attorney(s) to notify others
          when your attorney(s) intend to begin exercising power for financial
          matters or require your attorney(s) to provide information about their
          dealings with your finances or investments.
        </p>
        <p>
          People who can be notified include
          <span
            class="definition-link"
            @click="showDefinition = true"
          >
            you (the principal), the other current attorney(s) that you may have
            appointed and/or a person you nominate.
          </span>
        </p>
      </div>
    </notification-box>

    <h3 class="mb-4">Who would you like to notify?</h3>

    <item-checkbox
      :value="local.financialNotify.me"
      @select="local.financialNotify.me = !local.financialNotify.me"
      title="Me (the principal)"
      class="mb-4"
      ref="meSection"
    />
    <item-checkbox
      v-if="local.attorneys.length > 1 || local.successives.length > 1"
      :value="local.financialNotify.otherAttorneys"
      @select="
        local.financialNotify.otherAttorneys =
          !local.financialNotify.otherAttorneys
      "
      title="My other current attorney(s)"
      class="mb-4"
      ref="otherAttorneysSection"
    />
    <item-checkbox
      :value="showSelectPersons"
      @select="showSelectPersons = !showSelectPersons"
      title="Nominated person(s)"
      ref="otherSection"
      class="mb-4"
    >
      <v-expand-transition>
        <div
          v-if="showSelectPersons"
          class="mx-4"
        >
          <transition
            name="component-fade"
            mode="out-in"
          >
            <div
              v-if="!localFormatted.financialNotify.other.length"
              class="text-no-result"
              key="empty"
            >
              - You have not added any people -
            </div>
            <div
              key="list"
              v-else
            >
              <transition-group
                name="component-fade"
                mode="out-in"
              >
                <item-person
                  v-for="(item, index) in localFormatted.financialNotify.other"
                  :key="item + index"
                  class="mb-2"
                  :id="item"
                  :recommended="['phone', 'email']"
                  :editable="editable"
                  type="person"
                  @remove="removePersonToNotify(index)"
                >
                  <template v-slot:details="{ entity }">
                    <div
                      v-if="entity.type === 'person'"
                      class="text-sm text-cutoff opacity-70 py-1"
                    >
                      <span
                        v-if="!allRequiredFieldsComplete(entity)"
                        class="font-italic"
                        >- Information required -</span
                      >
                      <span v-else>
                        {{ entity.address }}
                      </span>
                    </div>
                  </template>
                </item-person>
              </transition-group>
            </div>
          </transition>
          <div class="mb-2">
            <btn-add-large
              label="Add person"
              class="mb-2"
              @click="openDrawerPerson = true"
              @clickDisabled="disabledMessage()"
              :disabled="isMaximumSelected"
            />
          </div>
          <v-input
            :error-messages="
              showErrors.otherPeople && errorMessages.otherPeople
                ? errorMessages.otherPeople
                : null
            "
          />
          <br />
        </div>
      </v-expand-transition>
    </item-checkbox>

    <div v-if="personsToNotify">
      <br />
      <h3 class="mb-4">What information must be provided?</h3>

      <div class="mb-4">
        <item-checkbox
          :value="localFormatted.financialNotify.what.writtenNotice"
          @select="
            local.financialNotify.what.writtenNotice =
              !local.financialNotify.what.writtenNotice
          "
          title="Written notice"
          text="My attorney(s) for financial matters must provide written notice that they intend to begin exercising power for financial matters under this enduring power of attorney before exercising the power for the first time."
          class="mb-2"
        />
        <item-checkbox
          :value="localFormatted.financialNotify.what.allRecords"
          @select="
            local.financialNotify.what.allRecords =
              !local.financialNotify.what.allRecords
          "
          title="All financial records and accounts"
          text="My attorney(s) for financial matters must provide all financial records and accounts, including financial documents such as bank statements and receipts showing any transaction my attorney(s) have made for me."
          class="mb-2"
        />
        <item-checkbox
          :value="showAboveAmountTextbox"
          @select="toggleShowAboveAmountTextbox"
          title="Records relating to transactions above a nominated amount"
          text="If I set a limit of $500 for example, my attorney(s) will need to provide receipts or statements showing all transactions of $500 or more."
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showAboveAmountTextbox"
              class="mx-4"
            >
              <h3 class="my-4">Please specify the nominated amount:</h3>
              <v-text-field
                v-bind="textField"
                prefix="$"
                type="number"
                inputmode="numeric"
                hide-spin-buttons
                @keypress="preventNonNumeric"
                v-model="local.financialNotify.what.aboveAmount"
                :error-messages="
                  showErrors.aboveAmount && errorMessages.aboveAmount
                    ? errorMessages.aboveAmount
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-checkbox>
        <item-checkbox
          :value="localFormatted.financialNotify.what.assets"
          @select="
            local.financialNotify.what.assets =
              !local.financialNotify.what.assets
          "
          title="Records and accounts for all assets including property, investments and vehicles"
          text="Whenever my attorney(s) for financial matters deal with my assets, they will need to provide records of their dealings. This could include records showing the receipt of the proceeds of sale of my car or any shares that I own."
          class="mb-2"
        />
        <item-checkbox
          :value="localFormatted.financialNotify.what.summaries"
          @select="
            local.financialNotify.what.summaries =
              !local.financialNotify.what.summaries
          "
          title="Summaries of income, expenditure and assets"
          text="My attorney(s) will need to provide a summary of financial transactions that they have made for me."
          class="mb-2"
        />
        <item-checkbox
          :value="localFormatted.financialNotify.what.plansAndAdvice"
          @select="
            local.financialNotify.what.plansAndAdvice =
              !local.financialNotify.what.plansAndAdvice
          "
          title="Copies of financial management plans and financial advice obtained"
          text="If my attorney(s) engage a financial advisor or planner to give my attorney(s) advice about my finances, then my attorney(s) will be responsible for providing a copy of the advice or plan."
          class="mb-2"
        />
        <item-checkbox
          :value="showOtherInformationTextbox"
          @select="toggleShowOtherInformationTextbox"
          title="Other"
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showOtherInformationTextbox"
              class="mx-4"
            >
              <h3 class="my-4">
                Please specify the information to be provided:
              </h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-if="showOtherInformationTextbox"
                v-bind="textAreaFieldGrow"
                v-model="local.financialNotify.what.otherInformation"
                :error-messages="
                  showErrors.otherInformation && errorMessages.otherInformation
                    ? errorMessages.otherInformation
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-checkbox>
      </div>
      <v-input
        :error-messages="
          showErrors.information && errorMessages.information
            ? errorMessages.information
            : null
        "
      />
    </div>

    <div v-if="personsToNotify && informationToNotify">
      <br />
      <h3 class="mb-4">When must the information be provided?</h3>

      <div class="mb-4">
        <item-checkbox
          :value="localFormatted.financialNotify.when.onRequest"
          @select="
            local.financialNotify.when.onRequest =
              !local.financialNotify.when.onRequest
          "
          title="On request"
          text="My attorney(s) must provide the information on request at any time by the person(s) to be notified."
          class="mb-2"
        />
        <item-checkbox
          :value="showTimeframeTextbox"
          @select="toggleShowTimeframeTextbox"
          title="On a regular timeframe"
          text="My attorney(s) must provide the information on a regular timeframe (e.g., annually on 1 July of each year or quarterly starting on 1 July)."
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showTimeframeTextbox"
              class="mx-4"
            >
              <h3 class="my-4">Please state the timeframe:</h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.financialNotify.when.onTimeframe"
                :error-messages="
                  showErrors.onTimeframe && errorMessages.onTimeframe
                    ? errorMessages.onTimeframe
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-checkbox>
        <item-checkbox
          :value="showOtherWhenTextbox"
          @select="toggleShowOtherWhenTextbox"
          title="Other"
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showOtherWhenTextbox"
              class="mx-4"
            >
              <h3 class="my-4">Please specify:</h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.financialNotify.when.onOther"
                :error-messages="
                  showErrors.onOther && errorMessages.onOther
                    ? errorMessages.onOther
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-checkbox>
        <v-input
          :error-messages="
            showErrors.commencement && errorMessages.commencement
              ? errorMessages.commencement
              : null
          "
        />
      </div>
    </div>

    <div class="d-flex align-center">
      <v-btn
        v-bind="backButton"
        @click="close"
        ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >{{ submitLabel }}</v-btn
      >
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add person"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addPersonToNotify"
        :exclude="excludePeople"
        :recommended="['phone', 'email']"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
      />
    </drawer>

    <drawer
      :open.sync="showDefinition"
      title="Explanation"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">You (the principal)</div>
        <p>
          While your attorney(s) must always keep you informed and seek your
          views about every decision they make, this instruction will require
          your attorney(s) to notify you in writing, even if you have lost
          capacity. Your capacity may change or fluctuate over time, and your
          attorney(s) have a duty to keep you informed about decisions they are
          making.
        </p>
        <br />
        <div class="text-lg font-weight-bold mb-4">
          The other current attorney(s) that you may have appointed
        </div>
        <p>
          If you have appointed multiple attorneys, you can require an
          attorney(s) to notify other current attorney(s) before they make a
          decision. A current attorney means any other attorney appointed for
          financial matters and who can also make decisions for you at the time
          the notification must be given.
        </p>
        <br />
        <div class="text-lg font-weight-bold mb-4">A person you nominate</div>
        <p>
          You can nominate a trusted person who your attorney(s) are required to
          notify. It may be a good idea to nominate a trusted person who is
          completely independent from your attorney(s) and who can check that
          your attorney(s) are making decisions in your best interests.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import DateField from '@/components/fields/DateField'

import { qld_epa_required } from '../../../../../../config/poaRequiredFields.js'
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import {
  isEmptyTrimmed,
  isNumericString,
  stripNumericString,
  trimIfExists,
} from '../../../utils.js'

const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'FinancialNotify',
  mixins: [forms(storeName, documentId), entitiesForms('', '')],
  components: {
    DateField,
  },
  data: () => ({
    showErrors: {
      otherPeople: false,
      aboveAmount: false,
      otherInformation: false,
      onTimeframe: false,
      onOther: false,
      information: false,
      commencement: false,
    },
    showDefinition: false,
    editable: { name: true, address: true, phone: true, email: true },
    required: qld_epa_required,
    showSelectPersons: false,
    showAboveAmountTextbox: false,
    showOtherInformationTextbox: false,
    showTimeframeTextbox: false,
    showOtherWhenTextbox: false,
  }),
  mounted() {
    this.showSelectPersons = this.local.financialNotify.other.length
    this.showAboveAmountTextbox = this.local.financialNotify.what.aboveAmount
      ? true
      : false
    this.showOtherInformationTextbox = this.local.financialNotify.what
      .otherInformation
      ? true
      : false
    this.showTimeframeTextbox = this.local.financialNotify.when.onTimeframe
      ? true
      : false
    this.showOtherWhenTextbox = this.local.financialNotify.when.onOther
      ? true
      : false
  },
  methods: {
    preventNonNumeric(e) {
      if (['.', 'e', '+', '-'].includes(e.key)) e.preventDefault()
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          financialNotify: {
            ...this.localFormatted.financialNotify,
            what: {
              ...this.localFormatted.financialNotify.what,
              otherInformation: trimIfExists(
                this.localFormatted.financialNotify.what.otherInformation
              ),
            },
            when: {
              ...this.localFormatted.financialNotify.when,
              onTimeframe: trimIfExists(
                this.localFormatted.financialNotify.when.onTimeframe
              ),
              onOther: trimIfExists(
                this.localFormatted.financialNotify.when.onOther
              ),
            },
          },
        },
      })
    },
    toggleShowAboveAmountTextbox() {
      this.setAllShowErrors(false)
      this.showAboveAmountTextbox = !this.showAboveAmountTextbox
    },
    toggleShowOtherInformationTextbox() {
      this.setAllShowErrors(false)
      this.showOtherInformationTextbox = !this.showOtherInformationTextbox
    },
    toggleShowTimeframeTextbox() {
      this.setAllShowErrors(false)
      this.showTimeframeTextbox = !this.showTimeframeTextbox
    },
    toggleShowOtherWhenTextbox() {
      this.setAllShowErrors(false)
      this.showOtherWhenTextbox = !this.showOtherWhenTextbox
    },
    addPersonToNotify(id) {
      this.setAllShowErrors(false)
      this.local.financialNotify.other.push(id)
      this.openDrawerPerson = false
    },
    removePersonToNotify(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.financialNotify.other, index)
    },
    disabledMessage() {
      if (this.isMaximumSelected) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Maximum of 20 people.',
        })
        return
      }
    },
  },
  computed: {
    excludePeople() {
      const jointAttorneys =
        this.local.attorneys.length > 1 && this.local.appointment === 'jointly'
          ? this.local.attorneys
          : []
      return [...this.localFormatted.financialNotify.other, ...jointAttorneys]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.financialNotify.other.length > 20)
        msgs.other = 'Maximum of 20 people with benefits.'
      if (
        this.showSelectPersons &&
        this.localFormatted.financialNotify.other.length < 1
      )
        msgs.otherPeople =
          'You have selected to nominate people to notify. Please add them.'
      if (
        this.personsToNotify &&
        this.showAboveAmountTextbox &&
        (isEmptyTrimmed(this.localFormatted.financialNotify.what.aboveAmount) ||
          !isNumericString(
            this.localFormatted.financialNotify.what.aboveAmount
          ))
      )
        msgs.aboveAmount =
          'Please specify a whole numeric value with no other non-numeric characters.'
      if (
        this.personsToNotify &&
        this.showOtherInformationTextbox &&
        isEmptyTrimmed(
          this.localFormatted.financialNotify.what.otherInformation
        )
      )
        msgs.otherInformation =
          'Please specify the details about the information to be provided.'
      if (
        this.personsToNotify &&
        this.showTimeframeTextbox &&
        isEmptyTrimmed(this.local.financialNotify.when.onTimeframe)
      )
        msgs.onTimeframe = 'Please specify the timeframe.'
      if (
        this.personsToNotify &&
        this.showOtherWhenTextbox &&
        isEmptyTrimmed(this.local.financialNotify.when.onOther)
      )
        msgs.onOther = 'Please specify when the information must be provided.'
      if (
        !msgs.otherInformation &&
        !msgs.aboveAmount &&
        this.personsToNotify &&
        !this.informationToNotify
      )
        msgs.information = 'Please select the information to be provided.'
      if (
        this.personsToNotify &&
        this.informationToNotify &&
        !this.timeToNotify &&
        !msgs.onTimeframe &&
        !msgs.onOther
      )
        msgs.commencement =
          'Please select when the information must be provided.'
      return msgs
    },
    isMaximumSelected() {
      return this.localFormatted.financialNotify.other.length >= 20
    },
    personsToNotify() {
      return (
        this.local.financialNotify.me ||
        this.local.financialNotify.otherAttorneys ||
        (this.showSelectPersons && this.local.financialNotify.other.length)
      )
    },
    informationToNotify() {
      return (
        this.local.financialNotify.what.writtenNotice ||
        this.local.financialNotify.what.allRecords ||
        (this.showAboveAmountTextbox &&
          this.local.financialNotify.what.aboveAmount &&
          isNumericString(
            this.localFormatted.financialNotify.what.aboveAmount
          )) ||
        this.local.financialNotify.what.assets ||
        this.local.financialNotify.what.summaries ||
        this.local.financialNotify.what.plansAndAdvice ||
        (this.showOtherInformationTextbox &&
          !isEmptyTrimmed(this.local.financialNotify.what.otherInformation))
      )
    },
    timeToNotify() {
      return (
        this.local.financialNotify.when.onRequest ||
        (this.showTimeframeTextbox &&
          !isEmptyTrimmed(this.local.financialNotify.when.onTimeframe)) ||
        (this.showOtherWhenTextbox &&
          !isEmptyTrimmed(this.local.financialNotify.when.onOther))
      )
    },
    localFormatted() {
      return {
        financialNotify: {
          me: this.local && this.local.financialNotify.me ? true : false,
          otherAttorneys:
            this.local &&
            (this.local.attorneys.length > 1 ||
              this.local.successives.length > 1) &&
            this.local.financialNotify.otherAttorneys
              ? true
              : false,
          other:
            this.showSelectPersons && this.local
              ? this.local.financialNotify.other
              : [],
          what: {
            writtenNotice: this.local.financialNotify.what?.writtenNotice
              ? true
              : false,
            allRecords: this.local.financialNotify.what?.allRecords
              ? true
              : false,
            aboveAmount:
              this.showAboveAmountTextbox &&
              this.local.financialNotify.what?.aboveAmount
                ? stripNumericString(
                    this.local.financialNotify.what.aboveAmount
                  )
                : null,
            assets: this.local.financialNotify.what?.assets ? true : false,
            summaries: this.local.financialNotify.what?.summaries
              ? true
              : false,
            plansAndAdvice: this.local.financialNotify.what?.plansAndAdvice
              ? true
              : false,
            otherInformation: this.showOtherInformationTextbox
              ? this.local.financialNotify.what?.otherInformation
              : null,
          },
          when: {
            onRequest: this.local.financialNotify.when.onRequest,
            onTimeframe: this.showTimeframeTextbox
              ? this.local.financialNotify.when.onTimeframe
              : null,
            onOther: this.showOtherWhenTextbox
              ? this.local.financialNotify.when.onOther
              : null,
          },
        },
      }
    },
  },
}
</script>
