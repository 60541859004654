<template>
  <div class="d-inline-block">
    <span
      class="definition-link"
      @click="showDefinition = true"
    >
      paid carer
    </span>
    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">Paid carer</div>
        <p>
          A paid carer is someone who is paid a fee or wage to care for a person
          but not someone receiving a carer’s pension or benefit.
        </p>
        <p>
          A person who is living with you and receives a carer's allowance from
          the government can be your attorney.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'PaidCarerDefinition',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
