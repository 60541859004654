<template>
  <div>
    <div v-if="local">
      <h2 class="mb-2">Attorney(s)</h2>
      <h3 class="mb-4">{{ subheadingText }}</h3>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You can appoint one or more attorneys to make decisions about
            {{ mattersText }} matters for you.
          </p>
          <p v-if="local.personalMatters">
            Personal matters relate to personal or lifestyle decisions. This
            includes decisions about support services, where and with whom you
            live, health care, and legal matters that do not relate to your
            financial or property matters.
          </p>
          <p v-if="local.financialMatters">
            Financial matters relate to your financial or property affairs
            including paying expenses, making investments, selling property or
            carrying on a business.
          </p>
          <p>Add up to 4 people as your attorneys.</p>
          <p>Your attorney(s) must:</p>
          <ul class="mb-4">
            <li>
              have capacity to make decisions about the matter for which they
              are being appointed; and
            </li>
            <li>be 18 years or older; and</li>
            <li>
              not be a service provider for a
              <residential-service-definition /> if you are a resident there;
              and
            </li>
            <li v-if="local.financialMatters">
              not be bankrupt or taking advantage of the laws of bankruptcy; and
            </li>
            <li>
              not be your <paid-carer-definition /> in the previous three years
              or your <health-provider-definition />.
            </li>
          </ul>
        </div>
      </notification-box>

      <h3 class="mb-4">Your attorney(s):</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.attorneys.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one attorney -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.attorneys"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              :recommended="['phone', 'email']"
              type="person"
              @remove="removeAttorney(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!allRequiredFieldsComplete(entity)"
                    class="font-italic"
                    >- Information required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.attorneys && errorMessages.attorneys
            ? errorMessages.attorneys
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.attorneys.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">
            How must your attorneys make decisions for
            {{ mattersText }} matters?
          </h2>
          <item-select
            v-for="item in appointmentOptions.slice(0, -1)"
            :key="item.key"
            :value="item.key === localFormatted.appointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            class="mb-2"
          />
          <item-select
            :value="
              localFormatted.appointment ===
              appointmentOptions[appointmentOptions.length - 1].key
            "
            @select="
              selectAppointmentOption(
                appointmentOptions[appointmentOptions.length - 1].key
              )
            "
            :title="appointmentOptions[appointmentOptions.length - 1].label"
            :text="appointmentOptions[appointmentOptions.length - 1].text"
          />
          <v-input
            :error-messages="
              showErrors.appointment && errorMessages.appointment
                ? errorMessages.appointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div
          v-if="
            this.local.attorneys.length > 1 && local.appointment === 'jointly'
          "
          class="mb-4"
        >
          <h2 class="mb-4">
            Would you like the appointment of all attorneys to be terminated if
            any one of them dies or loses capacity to make decisions for you?
          </h2>
          <item-select
            v-for="(item, index) in appointmentTerminationOptions"
            :key="item.key"
            :value="item.key === localFormatted.appointmentTermination"
            @select="selectAppointmentTerminationOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{
              'mb-2': index + 1 < appointmentTerminationOptions.length,
            }"
          />
          <v-input
            :error-messages="
              showErrors.appointmentTermination &&
              errorMessages.appointmentTermination
                ? errorMessages.appointmentTermination
                : null
            "
          />
        </div>
      </v-expand-transition>

      <v-alert
        v-if="showSuccessivesClearedWarning"
        outlined
        color="#d36a00"
        type="warning"
        text
        icon="mdi-alert-circle-outline"
      >
        Making the proposed change will result in the removal of any existing
        appointment of successive attorneys. A new appointment of successive
        attorneys will need to be made.
      </v-alert>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
        :recommended="['phone', 'email']"
      />
    </drawer>
  </div>
</template>

<script>
import { qld_epa_required } from '../../../../../../config/poaRequiredFields'
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import { appointmentOptions, appointmentTerminationOptions } from '../../lists'
import HealthProviderDefinition from './components/HealthProviderDefinition.vue'
import PaidCarerDefinition from './components/PaidCarerDefinition.vue'
import ResidentialServiceDefinition from './components/ResidentialServiceDefinition.vue'
import VacancyDefinition from './components/VacancyDefinition.vue'

const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'Attorneys',
  components: {
    VacancyDefinition,
    PaidCarerDefinition,
    HealthProviderDefinition,
    ResidentialServiceDefinition,
  },
  mixins: [
    forms(storeName, documentId),
    entitiesForms('attorneys', 'Maximum of 4 personal attorneys.'),
  ],
  data: () => ({
    showErrors: {
      attorneys: false,
      appointment: false,
      appointmentTermination: false,
    },
    editable: { name: true, address: true, phone: true, email: true },
    required: qld_epa_required,
    appointmentOptions: appointmentOptions,
    appointmentTerminationOptions: appointmentTerminationOptions,
  }),
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          ...this.localFormatted,
        },
      })
    },
    addAttorney(id) {
      this.setAllShowErrors(false)
      this.local.attorneys.push(id)
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.attorneys, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.appointment = option
    },
    selectAppointmentTerminationOption(option) {
      this.setAllShowErrors(false)
      this.local.appointmentTermination = option
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.attorneys,
        ...this.local.successives.map((s) => s.entity),
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.attorneys.length)
        msgs.attorneys = 'Select at least one attorney.'
      if (this.localFormatted.attorneys.length > 4)
        msgs.attorneys = 'Maximum of 4 attorneys.'
      if (
        this.localFormatted.attorneys.length > 1 &&
        !this.localFormatted.appointment
      )
        msgs.appointment = 'Required'
      if (
        this.localFormatted.attorneys.length > 1 &&
        this.localFormatted.appointment === 'jointly' &&
        !this.localFormatted.appointmentTermination
      )
        msgs.appointmentTermination = 'Required'
      return msgs
    },
    subheadingText() {
      if (this.local.financialMatters && this.local.personalMatters)
        return 'For Personal (inc. health) and Financial Matters'
      if (this.local.financialMatters && !this.local.personalMatters)
        return 'For Financial Matters'
      if (!this.local.financialMatters && this.local.personalMatters)
        return 'For Personal (inc. health) Matters'
      return ''
    },
    mattersText() {
      if (this.local.financialMatters && this.local.personalMatters)
        return 'personal (inc. health) and financial'
      if (this.local.financialMatters && !this.local.personalMatters)
        return 'financial'
      if (!this.local.financialMatters && this.local.personalMatters)
        return 'personal (inc. health)'
      return ''
    },
    showSuccessivesClearedWarning() {
      return (
        this.local.successivesStyle === 'per-primary' &&
        !(
          this.localFormatted.appointment === 'jointly' &&
          this.localFormatted.appointmentTermination === 'not-terminated'
        )
      )
    },
    localFormatted() {
      const appointment =
        this.local.attorneys.length > 1 ? this.local.appointment : null
      const appointmentTermination =
        this.local.attorneys.length > 1 && this.local.appointment === 'jointly'
          ? this.local.appointmentTermination
          : null

      const successivesMustBeCleared =
        this.local.successivesStyle === 'per-primary' &&
        !(
          appointment === 'jointly' &&
          appointmentTermination === 'not-terminated'
        )

      return {
        attorneys: this.local.attorneys,
        appointment,
        appointmentTermination,

        successivesStyle: successivesMustBeCleared
          ? 'none'
          : this.local.successivesStyle,
        successives: successivesMustBeCleared ? [] : this.local.successives,
        successiveAppointment: successivesMustBeCleared
          ? null
          : this.local.successiveAppointment,
        successiveAppointmentTermination: successivesMustBeCleared
          ? null
          : this.local.successiveAppointmentTermination,
      }
    },
  },
}
</script>
