<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Successive attorneys <span class="optional">(optional)</span>
      </h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Definition</div>
          <p>
            Successive attorneys only have power to act when the power given to
            an original attorney ends.
          </p>
          <p>
            An attorney's power can end for a number of reasons. For example,
            the attorney may die, or lose capacity to make decisions for you due
            to illness or accident.
          </p>
        </div>
      </notification-box>
      <br />
      <h3 class="mb-4">Would you like to appoint successive attorney(s)?</h3>

      <item-select
        :value="appointSuccessives === false"
        @select="selecteSuccessivesStyle('none'), (appointSuccessives = false)"
        title="No"
        text="I don't want to appoint any successive attorney(s)."
        class="mb-2"
      />
      <item-select
        :value="appointSuccessives === true"
        @select="selectYesSuccessives()"
        title="Yes"
        text="I want to appoint successive attorney(s)."
        class="mb-2"
      />
      <br />
      <div
        v-if="
          appointSuccessives &&
          attorneys.length > 1 &&
          local.appointment === 'jointly' &&
          local.appointmentTermination === 'not-terminated'
        "
      >
        <h3 class="mb-4">
          When would you like your successive attorney(s) to act?
        </h3>
        <item-select
          :value="localFormatted.successivesStyle === 'general'"
          @select="selecteSuccessivesStyle('general')"
          title="Option 1"
          text="I want my successive attorney(s) to act if all of the attorneys have died or lost capacity to make decisions for me."
          class="mb-2"
        />
        <item-select
          :value="localFormatted.successivesStyle === 'per-primary'"
          @select="selecteSuccessivesStyle('per-primary')"
          title="Option 2"
          text="I want my successive attorney(s) to act jointly in place of specific attorneys who have died or lost capacity to make decisions for me."
          class="mb-2"
        />
        <v-input
          :error-messages="
            showErrors.successivesStyle && errorMessages.successivesStyle
              ? errorMessages.successivesStyle
              : null
          "
        />
        <br />
      </div>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <!-- general -->
        <div v-if="localFormatted.successivesStyle === 'general'">
          <h3 class="mb-4">Your successive attorney(s):</h3>
          <transition
            name="component-fade"
            mode="out-in"
          >
            <div
              v-if="!localFormatted.successives.length"
              class="text-no-result"
              key="empty"
            >
              - You have not added any successive attorneys -
            </div>
            <div
              key="list"
              v-else
            >
              <transition-group
                name="component-fade"
                mode="out-in"
              >
                <item-person
                  v-for="(item, index) in localFormatted.successives"
                  :key="item + index"
                  class="mb-2"
                  :id="item.entity"
                  :editable="editable"
                  :recommended="['phone', 'email']"
                  type="person"
                  @remove="removeSuccessiveAttorney(index)"
                >
                  <template v-slot:details="{ entity }">
                    <div
                      v-if="entity.type === 'person'"
                      class="text-sm text-cutoff opacity-70 py-1"
                    >
                      <span
                        v-if="!entity.address"
                        class="font-italic"
                        >- Address Required -</span
                      >
                      <span v-else>
                        {{ entity.address }}
                      </span>
                    </div>
                  </template>
                </item-person>
              </transition-group>
            </div>
          </transition>
          <div class="text-label my-4">Add a successive attorney:</div>
          <div class="mb-2">
            <btn-add-large
              label="Add successive attorney (optional)"
              class="mb-2"
              @click=";(openDrawerPerson = true), (selectedAttorney = null)"
              @clickDisabled="disabledMessage()"
              :disabled="isMaximumSelected"
            />
          </div>
          <v-input
            :error-messages="
              showErrors.generalSuccessives && errorMessages.generalSuccessives
                ? errorMessages.generalSuccessives
                : null
            "
          />
          <br />
          <br />
        </div>

        <!-- per-primary -->
        <div v-else-if="localFormatted.successivesStyle === 'per-primary'">
          <div
            v-for="(primary, index) in attorneys"
            :key="primary.id"
            :class="{ 'mb-16': index + 1 < attorneys.length }"
          >
            <h3 class="mb-4">
              Your successive attorney(s) for {{ primary.name }}:
            </h3>
            <transition
              name="component-fade"
              mode="out-in"
            >
              <div
                v-if="
                  !localFormatted.successives.filter(
                    (s) => s.succeeds === primary.id
                  ).length
                "
                class="text-no-result"
                key="empty"
              >
                - You have not added any successive attorneys for
                {{ primary.name }} -
              </div>
              <div
                key="list"
                v-else
              >
                <transition-group
                  name="component-fade"
                  mode="out-in"
                >
                  <item-person
                    v-for="(item, index) in localFormatted.successives.filter(
                      (s) => s.succeeds === primary.id
                    )"
                    :key="item + index"
                    class="mb-2"
                    :id="item.entity"
                    :editable="editable"
                    type="person"
                    @remove="removePerPrimaryAttorney(item.entity)"
                  >
                    <template v-slot:details="{ entity }">
                      <div
                        v-if="entity.type === 'person'"
                        class="text-sm text-cutoff opacity-70 py-1"
                      >
                        <span
                          v-if="!entity.address"
                          class="font-italic"
                          >- Address Required -</span
                        >
                        <span v-else>
                          {{ entity.address }}
                        </span>
                      </div>
                    </template>
                  </item-person>
                </transition-group>
              </div>
            </transition>
            <div class="text-label my-4">
              Add a successive attorney for {{ primary.name }}:
            </div>
            <div class="mb-2">
              <btn-add-large
                label="Add successive attorney (optional)"
                class="mb-2"
                @click="
                  ;(openDrawerPerson = true), (selectedAttorney = primary.id)
                "
                @clickDisabled="disabledMessage()"
                :disabled="
                  localFormatted.successives.filter(
                    (s) => s.succeeds === primary.id
                  ).length > 3
                "
              />
            </div>
          </div>
          <v-input
            class="mb-2"
            :error-messages="
              showErrors.perPrimarySuccessives &&
              errorMessages.perPrimarySuccessives
                ? errorMessages.perPrimarySuccessives
                : null
            "
          />
        </div>
      </transition>

      <v-expand-transition>
        <div
          v-if="
            this.localFormatted.successivesStyle === 'general' &&
            this.localFormatted.successives.length > 1
          "
          class="mb-4"
        >
          <h3 class="mb-4">
            How would you like your successive attorneys to work together?
          </h3>
          <item-select
            v-for="(item, index) in successiveAppointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.successiveAppointment"
            @select="selectSuccessiveAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < successiveAppointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.successiveAppointment &&
              errorMessages.successiveAppointment
                ? errorMessages.successiveAppointment
                : null
            "
          />
          <transition
            name="component-fade"
            mode="out-in"
          >
            <div v-if="localFormatted.successiveAppointment === 'jointly'">
              <br />
              <br />
              <h3 class="mb-4">
                Would you like the appointment of all successive attorneys to be
                terminated if any one of them dies or loses capacity to make
                decisions for you?
              </h3>
              <item-select
                v-for="(item, index) in successiveAppointmentTerminationOptions"
                :key="item.key"
                :value="
                  item.key === localFormatted.successiveAppointmentTermination
                "
                @select="selectAppointmentTerminationOption(item.key)"
                :title="item.label"
                :text="item.text"
                :class="{
                  'mb-2':
                    index + 1 < successiveAppointmentTerminationOptions.length,
                }"
              />
              <v-input
                :error-messages="
                  showErrors.successiveAppointmentTermination &&
                  errorMessages.successiveAppointmentTermination
                    ? errorMessages.successiveAppointmentTermination
                    : null
                "
              />
            </div>
          </transition>
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addSuccessiveAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :recommended="['phone', 'email']"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import {
  successiveAppointmentOptions,
  successiveAppointmentTerminationOptions,
} from '../../lists.js'

const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'Successives',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('successives', 'Maximum of 4 successive attorneys.'),
  ],
  data: () => ({
    showErrors: {
      successivesStyle: false,
      generalSuccessives: false,
      successiveAppointment: false,
      successiveAppointmentTermination: false,
      perPrimarySuccessives: false,
    },
    editable: { name: true, address: true, phone: true, email: true },
    successiveAppointmentOptions: successiveAppointmentOptions,
    successiveAppointmentTerminationOptions:
      successiveAppointmentTerminationOptions,
    selectedAttorney: null,
    appointSuccessives: false,
  }),
  mounted() {
    this.appointSuccessives =
      this.local.successivesStyle === 'general' ||
      this.local.successivesStyle === 'per-primary'
    if (
      this.local.successivesStyle === 'per-primary' &&
      !(
        this.appointSuccessives &&
        this.attorneys.length > 1 &&
        this.local.appointment === 'jointly' &&
        this.local.appointmentTermination === 'not-terminated'
      )
    )
      this.local.successivesStyle = 'general'
  },
  methods: {
    selectYesSuccessives() {
      this.appointSuccessives = true
      if (
        !(
          this.attorneys.length > 1 &&
          this.local.appointment === 'jointly' &&
          this.local.appointmentTermination === 'not-terminated'
        )
      )
        this.local.successivesStyle = 'general'
    },
    addSuccessiveAttorney(id) {
      this.local.successives.push({
        entity: id,
        succeeds: this.selectedAttorney,
      })
      this.openDrawerPerson = false
    },
    removeSuccessiveAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.successives, index)
    },
    removePerPrimaryAttorney(entityId) {
      this.setAllShowErrors(false)
      this.local.successives = this.local.successives.filter(
        (s) => s.entity !== entityId
      )
    },
    selectSuccessiveAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.successiveAppointment = option
    },
    selecteSuccessivesStyle(option) {
      this.setAllShowErrors(false)
      this.local.successivesStyle = option
    },
    selectAppointmentTerminationOption(option) {
      this.setAllShowErrors(false)
      this.local.successiveAppointmentTermination = option
    },
    successiveFilter(successive) {
      if (successive.succeeds) return true
      return this.local.successivesStyle !== 'per-primary'
    },
  },
  computed: {
    attorneys() {
      return this[storeName].attorneys.map((a) =>
        this.entities_people.find((o) => o.id === a)
      )
    },
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.successives.map((s) => s.entity),
        ...this.local.attorneys,
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.appointSuccessives === true &&
        this.localFormatted.successivesStyle === 'none'
      )
        msgs.successivesStyle = 'Required'
      if (this.localFormatted.successivesStyle === 'general') {
        if (this.localFormatted.successives.length < 1)
          msgs.generalSuccessives =
            'You must add at least one successive attorney.'
        if (
          this.localFormatted.successives.length > 1 &&
          !this.localFormatted.successiveAppointment
        )
          msgs.successiveAppointment = 'Required'
        if (
          this.localFormatted.successiveAppointment === 'jointly' &&
          !this.localFormatted.successiveAppointmentTermination
        )
          msgs.successiveAppointmentTermination = 'Required'
      } else if (
        this.localFormatted.successivesStyle === 'per-primary' &&
        this.localFormatted.successives.filter((s) => s.succeeds).length < 1
      )
        msgs.perPrimarySuccessives =
          'You must add at least one successive attorney.'
      return msgs
    },
    localFormatted() {
      let successiveAppointment = this.local.successiveAppointment || null
      let successiveAppointmentOptions = successiveAppointment
        ? this.successiveAppointmentOptions.find(
            (option) => option.key === successiveAppointment
          ) || null
        : null
      let appointmentTermination =
        this.local?.successiveAppointmentTermination || null
      let appointmentTerminationOption = appointmentTermination
        ? this.successiveAppointmentTerminationOptions.find(
            (option) => option.key === appointmentTermination
          ) || null
        : null
      return {
        successivesStyle: this.local.successivesStyle,

        successives:
          this.local.successivesStyle !== 'general' &&
          this.local.successivesStyle !== 'per-primary'
            ? []
            : this.local.successives.filter(this.successiveFilter) || [],

        successiveAppointment:
          this.local.successives.length <= 1 ||
          this.local.successivesStyle !== 'general'
            ? null
            : successiveAppointmentOptions
              ? successiveAppointment
              : null,

        successiveAppointmentTermination:
          this.local.successives.length <= 1 ||
          this.local.successivesStyle !== 'general'
            ? null
            : successiveAppointment !== 'jointly'
              ? null
              : appointmentTerminationOption
                ? appointmentTermination
                : null,
      }
    },
  },
}
</script>
