<template>
  <div>
    <div>
      <section-item
        @edit="open('about-name')"
        class="mb-1"
      >
        <div class="text-label">Name</div>
        <div
          v-if="user_name.isComplete"
          class="mb-2"
        >
          {{ user_name.displayFull }}
        </div>
        <div
          v-else
          class="mb-2"
        >
          <chip-required @click="open('about-name')" />
        </div>
        <div class="text-label">Phone</div>
        <div v-if="user_phone.isComplete">{{ user_phone.value }}</div>
        <div v-else>
          <chip-required @click="open('about-name')" />
        </div>
      </section-item>
      <section-item
        @edit="open('about-dob')"
        class="mb-1"
      >
        <div class="text-label">Date of Birth</div>
        <div v-if="user_dob.isComplete">{{ user_dob.displayDate }}</div>
        <div v-else><chip-required @click="open('about-dob')" /></div>
      </section-item>
      <section-item
        @edit="open('about-address')"
        class="mb-1"
      >
        <div class="text-label">Address</div>
        <div v-if="user_address.isComplete">
          {{ user_address.displayAddress }}
        </div>
        <div v-else><chip-required @click="open('about-address')" /></div>
      </section-item>
      <section-item
        @edit="open('about-occupation')"
        class="mb-1"
        v-if="
          user_address.value.state === 'nsw' ||
          user_address.value.state === 'tas'
        "
      >
        <div class="text-label">Occupation</div>
        <div v-if="user_occupation.isComplete">
          {{ user_occupation.value }}
        </div>
        <div v-else><chip-required @click="open('about-occupation')" /></div>
      </section-item>
      <section-item
        v-if="!aboutYouComplete"
        class="my-2 warning-box"
      >
        <span class="warning-text"
          >To continue with the next step complete the information above</span
        >
      </section-item>
    </div>
    <dialog-full
      :open.sync="dialogOpen"
      title="About You"
      headline="Step 1"
      :path="gaUrl"
    >
      <component
        v-if="openForm"
        :is="`form-${openForm}`"
        :accessStatus="accessStatus"
        @close="close"
        @next="next"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChipRequired from '../../components/ui/ChipRequired'
import DialogFull from '../../components/wrapper/DialogFull'
import SectionItem from '../../components/wrapper/SectionItem'
import FormAboutAddress from './about/forms/AboutAddress'
import FormAboutDob from './about/forms/AboutDob'
import FormAboutName from './about/forms/AboutName'
import FormAboutOccupation from './about/forms/AboutOccupation'

export default {
  name: 'PoaAbout',
  emits: ['stepOneCompleted'],
  props: {
    step: {
      default: null,
    },
    access: {
      default: null,
    },
    accessStatus: {
      default: null,
    },
  },
  components: {
    ChipRequired,
    SectionItem,
    FormAboutDob,
    FormAboutOccupation,
    FormAboutName,
    FormAboutAddress,
    DialogFull,
  },

  data: () => ({
    openForm: false,
    gaUrl: '/',
  }),
  methods: {
    close() {
      this.$emit('stepOneCompleted', this.aboutYouComplete)
      this.openForm = null
    },
    next() {
      this.$emit('stepOneCompleted', this.aboutYouComplete)
      this.openForm = null
    },
    open(key) {
      this.openForm = key
    },
  },
  computed: {
    ...mapGetters('account', [
      'user_name',
      'user_phone',
      'user_dob',
      'user_address',
      'user_alternate_email',
      'user_occupation',
      'aboutYouComplete',
    ]),
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      },
    },
  },
}
</script>
