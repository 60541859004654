<template>
  <div class="d-inline-block">
    <div
      class="definition-link mb-4"
      @click="showDefinition = true"
    >
      Examples
    </div>
    <drawer
      :open.sync="showDefinition"
      title="Examples"
      class="faq-drawer"
    >
      <div class="pa-4">
        <ul>
          <li class="mb-4">Decisions about changing where I live.</li>
          <li class="mb-4">Major health decisions.</li>
          <li>With whom I have contact.</li>
        </ul>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'OtherPersonalInfoExample',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
