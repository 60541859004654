const appointmentOptions = [
  {
    label: `Jointly`,
    text: `All of my attorneys must agree on all decisions.`,
    key: `jointly`,
  },
  {
    label: `Severally`,
    text: `Any one of my attorneys may decide.`,
    key: `severally`,
  },
]

const appointmentTerminationOptions = [
  {
    label: 'Terminate appointment',
    text: `I want the appointment to be terminated if one of the attorneys dies or loses capacity to make decisions for me.`,
    key: 'terminated',
  },
  {
    label: 'Do not terminate appointment',
    text: `I do not want the appointment to be terminated if one of the attorneys dies or loses capacity to make decisions for me.`,
    key: 'not-terminated',
  },
]

const successiveAppointmentOptions = [
  {
    label: `Jointly`,
    text: `All of my successive attorneys must agree on all decisions.`,
    key: `jointly`,
  },
  {
    label: `Severally`,
    text: `Any one of my successive attorneys may decide.`,
    key: `severally`,
  },
]

const successiveAppointmentTerminationOptions = [
  {
    label: 'Terminate appointment',
    text: `I want the appointment to be terminated if one of the successive attorneys dies or loses capacity to make decisions for me.`,
    key: 'terminated',
  },
  {
    label: 'Do not terminate appointment',
    text: `I do not want the appointment to be terminated if one of the successive attorneys dies or loses capacity to make decisions for me.`,
    key: 'not-terminated',
  },
]

export {
  appointmentOptions,
  appointmentTerminationOptions,
  successiveAppointmentTerminationOptions,
  successiveAppointmentOptions,
}
