<template>
  <div>
    <p
      class="definition-link pr-4 pl-14 my-0 py-4"
      @click="showDefinition = true"
    >
      How is capacity determined?
    </p>
    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">
          How is capacity determined?
        </div>
        <p>
          If a person is unsure whether you have capacity, they can seek a
          report from a medical practitioner or a declaration from the
          Queensland Civil and Administrative Tribunal (QCAT) or the Supreme
          Court.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../../../../../../components/wrapper/Drawer'

export default {
  name: 'CapacityDefinition',
  components: { Drawer },
  data: () => ({
    showDefinition: false,
  }),
}
</script>
